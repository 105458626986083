<template>
      <div id="documents_pane">
        <div class="documents_pane_nav">
          <div class="pane_nav_left">
            <van-image lazy-load width="100%" height="100%" fit="contain" :src="`${imagePath}`">
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
          </div>
          <div class="pane_nav_right">
            <div class="pane_nav_right_title">
              <p>{{title}}</p>
            </div>
            <p>{{text}}</p>
          </div>
        </div>
      </div>
</template>

<script>
import VanImage from 'vant/lib/image';
import VanLoading from 'vant/lib/loading'
import 'vant/lib/button/style';
export default {
    props:['imagePath', 'title', 'text'],
    components:{
        VanImage,
        VanLoading
    }
}
</script>

<style scoped>
  .documents_pane_nav{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    /* justify-content: ; */
    align-items: center;
    /* padding-right: 10px; */
  }
  .documents_pane_nav .pane_nav_left{
    width: 45vw;
    height: auto;
  }
  .documents_pane_nav .pane_nav_right{
    max-width: 56vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-height: 28vw;
    padding: 3px 0;
  }
  .pane_nav_right .pane_nav_right_title{
    color: #333;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .pane_nav_right p:nth-child(2){
    color: #666;
    font-size: 14px;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (min-width: 768px) {
    .pane_nav_right .pane_nav_right_title{
      color: #333;
      font-size: 24px !important;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .pane_nav_right p:nth-child(2){
      color: #666;
      font-size: 18px;
      margin-top: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 1024px) {
    .pane_nav_right .pane_nav_right_title{
      color: #333;
      font-size: 34px !important;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .pane_nav_right p:nth-child(2){
      color: #666;
      font-size: 30px;
      margin-top: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 420px){
  .documents_pane_nav .pane_nav_right{
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    padding: 3px 0;
  }
  .pane_nav_right .pane_nav_right_title{
    color: #333;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pane_nav_right p:nth-child(2){
    color: #666;
    font-size: 12px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    width: 100%;
  }
  }
  @media screen and (max-width: 414px){
  .documents_pane_nav .pane_nav_right{
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    padding: 3px 0;
  }
  .pane_nav_right .pane_nav_right_title{
    color: #333;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pane_nav_right p:nth-child(2){
    color: #666;
    font-size: 10px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    width: 100%;
  }
  }
  @media screen and (max-width: 360px){
  .documents_pane_nav .pane_nav_right{
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    padding: 3px 0;
  }
  .pane_nav_right .pane_nav_right_title{
    color: #333;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pane_nav_right p:nth-child(2){
    color: #666;
    font-size: 9px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    width: 100%;
  }
  }
  @media screen and (max-width: 320px){
  .documents_pane_nav .pane_nav_right{
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;
    padding: 3px 0;
  }
  .pane_nav_right .pane_nav_right_title{
    color: #333;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pane_nav_right p:nth-child(2){
    color: #666;
    font-size: 8px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    width: 100%;
  }
  }
</style>