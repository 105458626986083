<template>
    <div style="width:100%;height:auto">
    <div id="wwytPane">
        <van-image id="centerImg" lazy-load width="75%" height="75%" fit="contain" :src="require('@/assets/wwyt/bg.png')">
        </van-image>
        <div class="wwytNav nav_top" style="margin-left: 1vw">
            <div :class="checked == 1 ? ' circ circ_check' : 'circ'" @click="checked = 1">
                <span style="margin-bottom: 0.3vw;text-align: center">
                    <van-image lazy-load width="80%" height="80%" fit="contain" :src="require('../assets/wwyt/xwgw.png')"></van-image>
                </span>
                <span>学术顾问</span>
            </div>
        </div>
        <div class="wwytNav nav_center" style="margin-bottom: 8vw">
            <div class="wwytSmallNav" style="justify-content:flex-start;padding-left: 4vw">
            <div :class="checked == 2 ? ' circ circ_check' : 'circ'" @click="checked = 2">
                <span style="margin-bottom: 0.3vw;text-align: center">
                    <van-image lazy-load width="80%" height="80%" fit="contain" :src="require('../assets/wwyt/tutor.png')"></van-image>
                </span>
                <span>学术Tutor</span>
            </div>
            </div>
            <div class="wwytSmallNav" style="justify-content:flex-end;padding-right: 4vw">
            <div :class="checked == 3 ? ' circ circ_check' : 'circ'" @click="checked = 3">
                <span style="margin-bottom: 0.3vw;text-align: center">
                    <van-image lazy-load width="80%" height="80%" fit="contain" :src="require('../assets/wwyt/wsds.png')"></van-image>
                </span>
                <span>文书导师</span>
            </div>
            </div>
        </div>
        <div class="wwytNav nav_bottom">
            <div class="wwytSmallNav" style="justify-content:center">
            <div :class="checked == 4 ? ' circ circ_check' : 'circ'" @click="checked = 4">
                <span style="margin-bottom: 0.3vw;text-align: center">
                    <van-image lazy-load width="80%" height="80%" fit="contain" :src="require('../assets/wwyt/wjrsds.png')"></van-image>
                </span>
                <span>外籍润色老师</span>
            </div>
            </div>
            <div class="wwytSmallNav" style="justify-content:center">
            <div :class="checked == 5 ? ' circ circ_check' : 'circ'" @click="checked = 5">
                <span style="margin-bottom: 0.3vw;text-align: center">
                    <van-image lazy-load width="80%" height="80%" fit="contain" :src="require('../assets/wwyt/xsgh.png')"></van-image>
                </span>
                <span>学术规划师</span>
            </div>
            </div>
        </div>
    </div>
    <div class="text_content">
      <span v-show="contentSpans[checked] != ''" style="display: block; width: 100%; text-align:center">{{contentSpans[checked]}}</span>
      <p>{{contents[checked]}}</p>
    </div>
    </div>
</template>

<script>
import VanImage from 'vant/lib/image';
import VanLoading from 'vant/lib/loading'
import 'vant/lib/button/style';
export default {
    data(){
        return{
            checked : 1,
            contentSpans: {
                1: '专业学术咨询顾问:',
                2: '同领域名校导师：',
                3: '资深后端文书导师：:',
                5: '学术产出质量把控专家：',
                4: '外籍润色：'
            },
            contents: {
                1: '团队成员大多有海外学习背景，熟知海外学习生活，多年申请经验，主要负责根据学生全程签约流程、根据学生申请规划全程跟进其进度，负责服务前、中、后期解答学生申博疑问。',
                2: '协助学生寻找研究方向，制定研究课题，辅导并协助学生完成符合申请要求的研究计划，为学生定制导师、项目和院校清单。',
                3: '负责学生基础文书（CV、RL、PS）的编辑与修改，套磁跟进，网申递交，面试辅导，及其他网申后跟进工作。',
                5: '全程监控学术产出质量和文书质量。',
                4: '曼博士外籍润色导师，均来自原生英语国家，负责为学生申请材料提供本土化语言润色服务。'
            }
        }
    },
    components:{
        VanImage,
        VanLoading
    }
}
</script>

<style scoped>
    #center_title{
        display: block;
        position: absolute;
        font-size: 24px;
        /* font-weight: 600;    */
        color: #fff;
        margin-top: 8vw;
        

    }
    @media (max-width: 420px){
        span{
            display: block;
            font-size: 14px;
        }
        
        .text_content p{
            margin-top: 8px !important;
            font-size: 14px !important;
        }
    }
    #wwytPane{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #centerImg{
        position: absolute;
        margin-top: 4vw;
        z-index: -1;
    }
    .wwytNav{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
    }
    .nav_top{
        justify-content: center;
    }
    .nav_center{
        justify-content: center;
        margin-top: -2vw;
        margin-bottom: 20vw;
    }
    .wwytSmallNav{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
    }
    .wwytNav .circ{
        width: 25vw;
        height: 25vw;
        border-radius: 50%;
        background: #A6B6F8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all .3s;
        cursor: pointer;
    }
    .circ span{
        color: #fff;
        display: block;
        line-height: 1.4em;
    }
    .circ_check{
        background: #8071F6 !important;
        color: #fff;
    }
    .text_content{
        min-height: 25vw;
        margin-top: 30px;
        color: #333;
        letter-spacing: 1px;
        font-size: 14px;
    }
    .text_content p{
        margin-top: 8px !important;
        font-size: 14px;
        text-align: center;
    }
    @media (min-width: 768px) {
        .text_content{
            min-height: 20vw;
            margin-top: 6vw;
            color: #333;
            letter-spacing: 1px;
            font-size: 22px;
        }
        .text_content p{
            margin-top: 8px;
            font-size: 22px !important;
        }
    }

</style>