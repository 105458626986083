<template>
    <div id="dot" @click="clickDot()">
        <div class="circ" :style="`background:${selected ? checked : normal}`" >
            <div class="smallCirc">
                <van-image style="transform: rotate(-45deg)" width="60%" fit="contain" height="60%" :src="`${icons[type]}`"></van-image>
            </div>
        </div>
        <div class="circEnd" :style="`background:${selected ? checked : normal}`"></div>
        <span>{{county[type]}}</span>
    </div>
</template>
<script>
import VanImage from 'vant/lib/image';
import VanLoading from 'vant/lib/loading'
import 'vant/lib/button/style';
export default {
    components:{
        VanImage,
        VanLoading
    },
    props:{
        type: String,
        selected: Boolean
    },
    data(){
        return{
            normal: '#8071F6',
            checked: '#71BF88',
            icons:{
                yg: require('@/assets/icon/county_icon/英国.png'),
                am: require('@/assets/icon/county_icon/澳门.png'),
                bm: require('@/assets/icon/county_icon/北美.png'),
                dyz: require('@/assets/icon/county_icon/大洋洲.png'),
                oz: require('@/assets/icon/county_icon/欧洲.png'),
                dny: require('@/assets/icon/county_icon/东南亚.png'),
            },
            county:{
                yg: '英国',
                am: '中国香港中国澳门',
                bm: '北美',
                dyz: '大洋洲',
                oz: '欧洲',
                dny: '东南亚'
            }
        }
    },
    methods:{
        clickDot(){
            let data = {
                county: this.type
            }
            this.$emit('check', data);
        }
    }
}
</script>
<style scoped>
    #dot{
        /* width: 100%;
        height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #dot span{
        margin-top: -0.2vw;
        font-size: 16px;
        max-width: 4em;
    }
    .circ{
        width: 9vw;
        height: 9vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50% 50% 0% 50%;
        transform: rotate(45deg);
    }
    .smallCirc{
        background: rgb(43,54,79);
        width: 6.3vw;
        height: 6.3vw;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .circEnd{
        margin-top: 2.5vw;
        width: 3vw;
        height: 1vw;
        border-radius: 50%;
    }
    @media screen and (max-width: 420px){
        #dot span{
            font-size: 12px !important;
        }
    }
    @media (min-width: 768px){
        .circ{
            width: 8vw !important;
            height: 8vw !important;
        }
        .smallCirc{
            width: 5.5vw !important;
            height: 5.5vw !important;
        }
    }
     @media (min-width: 1024px){
        .circ{
            width: 8vw !important;
            height: 8vw !important;
        }
        .smallCirc{
            width: 5.5vw !important;
            height: 5.5vw !important;
        }
    }
</style>
