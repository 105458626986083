<template>
    <div class="container" @touchend="state=!state">
      <img src="../assets/nav/logo_bottom@2x.png">
      <div class="navContent">
        <b>导航</b>
        <div class="navLine" >
          <span :class="{'rotate1':state}"></span>
          <span :class="{'center':true,'rotate2':state}"></span>
          <span :class="{'rotate3':state}"></span>
        </div>
      </div>
      <div :class="{'navInfo':true,'infoShow':state}">
        <div class="infoItem" @touchend="handleLink('index.html')">
          <span :class="{'show':title===''||title==='index.html'}">首页</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/uk.html')">
          <span :class="{'show':title==='mbs/uk'||title==='uk.html'}">英国</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/usa.html')">
          <span :class="{'show':title==='mbs/usa.html'}">美国</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/aus.html')">
          <span :class="{'show':title==='mbs/aus.html'}">澳洲</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/sg.html')">
          <span :class="{'show':title==='mbs/sg.html'}">新加坡</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/hk.html')">
          <span :class="{'show':title==='mbs/hk.html'}">香港澳门</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/can.html')">
          <span :class="{'show':title==='mbs/can.html'}">加拿大</span>
          <div class="icon"></div>
        </div>
        <div class="infoItem" @touchend="handleLink('mbs/eur.html')">
          <span :class="{'show':title==='mbs/eur.html'}">欧洲</span>
          <div class="icon"></div>
        </div>
         <div class="infoItem" @touchend="handleLink('mbs/aboutMe.html')">
          <span :class="{'show':title==='mbs/aboutMe.html'}">关于我们</span>
          <div class="icon"></div>
        </div>
        <!-- <div class="aboutMeWarp"  @touchend.stop="aboutMe=!aboutMe">
          <div class="aboutMeTitle">
            <span >关于我们</span>
            <div :class="{'icon':true,'showAboutMe':aboutMe}"></div>
          </div>
          <div :class="{'aboutMeContent':true,'showAboutMeContent':aboutMe}">
            <div class="infoItemNew" @touchend="handleLink('eur.html')" style="margin-top: 0.2rem">
              <span :class="{'spanNew':true,'show':title==='eur.html'}">师资力量</span>
            </div>
            <div class="infoItemNew" @touchend="handleLink('eur.html')">
              <span :class="{'spanNew':true,'show':title==='eur.html'}">学员案例</span>
            </div>
            <div class="infoItemNew" @touchend="handleLink('eur.html')">
              <span :class="{'spanNew':true,'show':title==='eur.html'}">企业介绍</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
</template>

<script>
export default {
    name: "nav_h5",
    components:{},
    props:[],
    data(){
          return{
            state:false,
            title:null, //当前页面标题高亮
            aboutMe:false
          }
        },
    methods:{
      //页面跳转
      handleLink(name) {
        window.location.href = `/${name}`;
      },
      //获取当前title
      getTitle(){
        let url = document.location.toString();
        let arrUrl = url.split("//");
        let start = arrUrl[1].indexOf("/");
        let relUrl = arrUrl[1].substring(start).split('/')[2];
        if(relUrl.indexOf("?") != -1){
          relUrl = relUrl.split("?")[0];
        }
        this.title=relUrl
        console.log(this.title)
      },
    },
  mounted() {
    this.getTitle()
  }
}
</script>

<style scoped type="text/less" lang="less">
@themeColor:#8071F6 ;
@themeFontFamily: PingFangSC-Regular, PingFang SC,Microsoft Yahe,serif;
.container{
  width: 100%;
  height: 2.8rem;
  background: #2B364F;
  padding: 0 2rem 0 1.5rem;
  // margin:0 1rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top:0;
  z-index: 999;
  img{
    width: 4.5rem;
    height: 1.3rem;
  }
  .navContent{
    width: 4rem;
    height: 0.43rem;
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
    b{
      font-size: 12px;
      font-family: @themeFontFamily;
      font-weight: 400;
      color: #FFFFFF;
      height: 0.33rem;
      line-height: 0.33rem;
    }
    .navLine{
      width: 0.7rem;
      height: 1rem;
      // padding-right: 2rem;
      margin-left: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      span{
        display: block;
        width: 0.8rem;
        background-color: #FFFFFF;
        height: 0.06rem;
        position: relative;
        top:0;
        transition: top .2s ease-in-out .3s,transform .3s ease-in-out;
      }
      .center{
        opacity: 1;
        transition: all .5s;
      }
      .rotate1{
        top:0.30rem;
        transform: rotate(45deg);
        transition: top .3s ease-in-out ,transform .3s ease-in-out .3s ;
      }
      .rotate2{
        opacity: 0;
        transition: all .5s;
      }
      .rotate3{
        top: -0.36rem;
        transform: rotate(-45deg);
        transition: top .3s ease-in-out ,transform .3s ease-in-out .3s ;
      }
    }
  }
  .navInfo{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    position: absolute;
    top: 2.8rem;
    left: 0;
    transform: scaleY(0);
    transform-origin: 50% 0 ;
    transition:all .3s ;
    padding: 1rem 2rem 2rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    
    .infoItem{
      height: auto;
      width: 19rem;
      border-bottom:0.01rem solid #D5D7DF ;
      display: flex;
      justify-content: space-between;
      margin-right: 3.5rem;
      // margin-top: 1rem;
      .show{
        color: #8071F6;
      }
      span{
        margin-top: 0.8rem;
        margin-bottom: 0.15rem;
        height: 1.5rem;
        font-size: 0.8rem;
        font-family: @themeFontFamily;
        font-weight: 400;
        color: #2B364F;
        line-height: 1rem;
      }
      .icon{
        width: 0.4rem;
        height: 0.7rem;
        background-size: cover;
        margin-top: 1.2rem;
        background-image: url("../assets/nav/nav_left.png");
        transition: all .3s;
        transform: rotate(0);
      }
    }
    .infoItem:active{
      background-color: rgba(0,0,0,0.1);
    }
  }
  .infoShow{
    transform: scaleY(1);
  }
  .aboutMeWarp{
    width: 25rem;
    max-height: 20rem;
    display: flex;
    flex-direction: column;
    transition: all .8s;
    padding-bottom: 0.15rem;
    border-bottom:0.01rem solid #D5D7DF ;
    margin-right: 3rem;
    .aboutMeTitle{
      width: 100%;
      display: flex;
      justify-content: space-between;
      span{
        margin-top: 1.5rem;
        height: 1rem;
        font-size: 1rem;
        font-family: @themeFontFamily;
        font-weight: 400;
        color: #2B364F;
        line-height: 1rem;
      }
      .icon{
        width: 0.5rem;
        height: 0.8rem;
        background-size: cover;
        margin-top: 1.2rem;
        background-image: url("../assets/nav/nav_left.png");
        transition: all .3s;
        transform: rotate(0);
      }
      .showAboutMe{
        transform: rotate(90deg);
      }
    }
    .aboutMeContent{
      opacity: 0;
      display: flex;
      flex-direction: column;
      width: 6rem;
      height: 0;
      box-sizing: border-box;
      padding: 0 0.2rem 0 0;
      transition:all .8s;
      overflow: hidden;
      transform: translateY(-0.5rem);
      .infoItemNew {
        // width: 20rem;
        height:5rem;
        display: flex;
        justify-content: space-between;
        .spanNew{
          margin: 0.2rem 0 0.2rem 1em;
          height: 1rem;
          font-size: 0.8rem;
          font-family: @themeFontFamily;
          font-weight: 400;
          color: #2B364F;
          line-height: 1rem;
        }
      }
    }
    .showAboutMeContent{
      opacity: 1;
      height:5rem ;
      transform: translateY(0);
    }
  }

}
</style>
