<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  mounted(){
    let url = document.referrer;
    if(this.$route.query.yd){
      this.cookies.set('xiaocms_yd', this.$route.query.yd, '1d');
    }else{
      this.cookies.set('xiaocms_yd', '', '1d');
    }
    if(url.indexOf('\/')){
      this.$cookies.set('xiaocms_http_referer', url, '1d');
    }else{
      this.$cookies.set('xiaocms_http_referer', '', '1d');
    }
  }, 
  methods: {
    linktab() {
      let goUrl = this.isMobile();
      if (goUrl === 1) {
        //移动端地址
        // location = "http://127.0.0.1:8043";
      } else {
        //PC地址
        location = "http://www.manbosi.com/"
      }
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      let goUrl = flag ? 1 : 0;
      return goUrl;
    },
  },
  beforeDestroy(){
    this.$cookies.remove('xiaocms_yd')
    this.$cookies.remove('xiaocms_http_referer')
  } ,
  created() {
	// other ...
	  this.linktab();
  },
}
</script>

<style>
*{
  font-family: PingFangSC-Regular, sans-serif;
  padding: 0;
  margin: 0;
}
</style>
