<template>
    <div id="compontent">
        <van-swiper  indicator-color="#575D6A" :lazy-render="true" initial-swipe="1" :duration="800" style="width: 100%; height: 100%; padding-left: 25vw" :width="itemWidth" :height="itemHeight">
            <van-swiper-item v-for="(item, index) in items" :key="index">
                <van-image width="95%" height="91%" :src="`${item}`"/>
            </van-swiper-item>
        </van-swiper>
    </div>
</template>

<script>
import VanSwiper from 'vant/lib/swipe';
import VanSwiperItem from 'vant/lib/swipe-item';
import VanImage from 'vant/lib/image';
import LazyLoad from 'vant/lib/lazyload';
export default {
    props:{
        items:Array,
    },
    data(){
        return{
            
        }
    },
    mounted(){
        // console.log(this.items);
    },
    computed:{
        itemWidth:function(){
            let superWidth = document.body.clientWidth;
            // console.log(superWidth / 2 - 20);
            return superWidth / 2 + 10;
        },
        itemHeight:function(){
            return this.itemWidth * 1.2;
        }
    },
    components: {
        VanSwiper,
        VanSwiperItem,
        VanImage,
        LazyLoad
    }
}
</script>

<style scoped>
    #compontent{
        width: 100%;
        height: auto;
        margin-top: 6px;
        display: flex;
        flex-direction: row;
    }
    /* /deep/ .van-swipe__indicator{
        margin-top:15px;
    } */
</style>