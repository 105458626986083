<template>
    <div id="foot" style="margin-top: 2em">
        <div class="foot-content flex-row" style="margin-top: 3em; margin-left: 0em">
            <div class="foot-left-pane flex-row flex-align-start">
                <div class="left-text-pane" style="margin-top: -0.3em;word-break: keep-all">
                    【中国总部】
                </div>
                <div class="right-text-pane">
                    <div class="row-pane"><span>曼汉教育科技(上海)有限公司</span></div>
                    <div class="row-pane"><i style="margin-top: -0.2em;width: 0.8em"><van-image width="100%" height="100%" :src="require('@/assets/foot/电 话.png')"/></i><span>联系电话：4000-972-558</span></div>
                    <div class="row-pane" style="display: flex; flex-direction: row;align-items: flex-start"><i style="margin-top: -0.2em;height: 0.9em;width:0.8em"><van-image width="100%" height="100%" :src="require('@/assets/foot/地址.png')"/></i><span style="word-break: keep-all;margin-botom:0.5em">公司地址：<div style="margin-top: 0.3em"></div>上海市长宁区金钟路968号凌空<div style="margin-top: 0.5em"></div>SOHO 3号楼201室</span></div>
                    <div class="row-pane flex-row" style="height: 4.5em;margin-top: 1.8em;margin-bottom: 0.5em">
                        
                        
                        <!-- <van-image style="width: 6em;height:6em; margin-left: 0.4em; font-size: 0.75em;display: flex;flex-direction: column;align-items: center;text-align:center" width="100%" height="100%" :src="require('@/assets/foot/erweima1.png')">
                            <span style="word-break: keep-all;margin: 0.5em auto;">中国客服</span>
                        </van-image>
                        <van-image style="width: 6em;height:6em; margin-left: 3.75em;font-size: 0.75em;;display: flex;flex-direction: column;align-items: center;text-align:center" width="100%" height="100%" :src="require('@/assets/foot/erweima2.png')"> 
                            <span style="word-break: keep-all;margin: 0.5em auto">英国客服</span>
                        </van-image> -->
                    </div>
                    <div class="row-pane" style="width: 15.13em;color: #fff">
                    </div>
                </div>
            </div>
            <div class="foot-right-pane flex-row flex-align-start">
                <div class="left-text-pane" style="margin-top: -0.3em;word-break: keep-all">
                    【英国】
                </div>
                <div class="right-text-pane">
                    <div class="row-pane"><span><i><van-image width="100%" height="100%" :src="require('@/assets/foot/电 话.png')"/></i>联系电话：+44 (0)776-790-4991<br/><span style="float:right;margin-top:0.38em">+44 (0)161-247-8520</span></span></div>
                    <div class="row-pane"><span><i><van-image width="100%" height="100%" :src="require('@/assets/foot/邮箱.png')"/></i>电子邮箱：<div style="margin-top: 0.3em"></div>info@minhandgroup.com</span></div>
                    <div class="row-pane"><span><i style="height: 0.9em"><van-image width="100%" height="100%" :src="require('@/assets/foot/地址.png')"/></i>公司地址：</span></div>
                    <div class="row-pane"><i></i><span>The Minster Building, Great Tower St,</span></div>
                    <div class="row-pane"><i></i><span>London EC3R 7AG(伦敦)</span></div>
                    <div class="row-pane"><i></i><span>Office 610, Peter House, Oxford St, Manchester M1 5AN (曼彻斯特)</span></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
#foot{
  width: 100%;
  height: 19.5em;
  background: #363D45;
  font-size: 16px;
}
@media (min-width: 320px){
  #foot{
    font-size: 9.7px !important;
    height: 14.5em;
  }
}

@media (min-width: 360px){
  #foot{
    font-size: 11.3px !important;
    height: 14.5em;
  }
}

@media (min-width: 375px){
  #foot{
    font-size: 11.5px !important;
    height: 14.5em;
  }
}
@media (min-width: 414px){
  #foot{
    font-size: 8px !important;
    height: 16.5em;
  }
}

@media (min-width: 420px){
  #foot{
    font-size: 8px !important;
    height: 16.5em;
  }
}
@media (min-width: 750px){
  #foot{
    font-size: 16px !important;
    height: 16.5em;
  }
}
@media (min-width: 768px){
  #foot{
    font-size: 24px !important;
    height: 14.5em;
  }
}
.foot-content {
        font-size: 0.75em;
  margin-top: 2.38em;
  display: flex; 
  flex-direction: row;
}
.foot-left-pane{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.foot-left-pane .left-text-pane {
  margin-left: 0.44em;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.foot-left-pane .right-text-pane {
  color: #BCBCBC;
}
.right-text-pane .row-pane {
  margin-bottom: 0.56em;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.06em;
}
.foot-right-pane {
  margin-left: 0.38em;
  display: flex;
  flex-direction: row;
}
.foot-right-pane .left-text-pane {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.foot-right-pane .right-text-pane {
  color: #BCBCBC;
}
.foot-right-pane .right-text-pane .row-pane {
  line-height: 1.06em;
  margin-bottom: 0.56em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

i {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  margin-right: 0.1em;
}
</style>
