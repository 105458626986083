<template>
  <div id="home" ref="home" class="home">
    <div id="head" class="banner">
      <van-image
        style="position: relative;"
        lazy-load
        width="100%"
        height="100%"
        fit="contain"
        :src="require('@/assets/顶部banner.png')"
      >

        <template v-slot:loading>
          <van-loading type="spinner" size="80" />
        </template>
        
        <van-image
          style="position: absolute;top: 0;left: 0; right: 0; bottom: 0;margin: 0 auto;margin-top: 14vw"
          lazy-load
          width="50%"
          height="10%"
          fit="contain"
          :src="require('@/assets/logo.png')"
        ></van-image>
        <div class="banner-text">
          <Nav_head></Nav_head>
          <div style="font-size:23px;">Lets Go!</div>
          <div style="font-size:36px;">海外博士申请</div>
          <div style="font-size:36px;"> 快人一步拿OFFER</div>
        </div>
        <div class="banner-bottom">
          <div class="banner-bottom-text1">
            <div class="banner-bottom-text-container">
              
            </div>
            <span>
              <van-image
              lazy-load
              width="12px"
              height="12px"
              fit="contain"
              line-height="12px"
              :src="require('@/assets/dui.png')"
              ></van-image> 学术科研</span>
            <span><van-image
              lazy-load
              width="12px"
              height="12px"
              fit="contain"
              line-height="12px"
              :src="require('@/assets/dui.png')"
              ></van-image> 高校就职</span>
            <span><van-image
              lazy-load
              width="12px"
              height="12px"
              fit="contain"
              line-height="12px"
              :src="require('@/assets/dui.png')"
              ></van-image> TOP名校申请</span>
          </div>
          <div class="banner-bottom-text2">
            <span><van-image
              lazy-load
              width="12px"
              height="12px"
              fit="contain"
              line-height="12px"
              :src="require('@/assets/dui.png')"
              ></van-image> 顶尖培养体系</span>
            <span><van-image
              lazy-load
              width="12px"
              height="12px"
              fit="contain"
              line-height="12px"
              :src="require('@/assets/dui.png')"
              ></van-image> 多元文化环境</span>
          </div>
        </div>
      </van-image>
    </div>
    <div class="admission" style="display: flex;">
      <div class="title" id="firstTitle"><h3>免费评估名校录取率!</h3></div>
      <home-form />
    </div>
    <div class="advantage">
      <div class="left-title">
        <i></i>
        <h2>海外读博有哪些优势</h2>
      </div>
      <div class="advantage-pane">
        <van-image
          width="100%"
          height="100%"
          :src="require('@/assets/科海外读博优势_科研培养.png')"
        />
      </div>
    </div>
    <div class="system">
      <div class="left-title">
        <i></i>
        <h2>热门留学地读博体系</h2>
      </div>
      <system-content-pane></system-content-pane>
    </div>

    <div class="documents">
      <div class="documents_title">
        <van-image
          lazy-load
          width="100%"
          height="100%"
          fit="contain"
          :src="require('@/assets/常用书材料.png')"
        />
      </div>
      <div class="documents_pane">
        <document-pane
          v-for="item in pageDefaultData"
          :key="item.id"
          :imagePath="item.path"
          :title="item.title"
          :text="item.text"
        />
      </div>
    </div>
    <div class="service">
      <div class="left-title innerTitle">
        <i></i>
        <h2>如何快速拿到海外名校OFFER?</h2>
      </div>
      <van-image
        lazy-load
        width="100%"
        height="100%"
        fit="contain"
        :src="require('@/assets/申请服务_详情.png')"
      />
    </div>
    <div class="service_content">
      <div class="service_content_title">
        <!-- <van-image
          lazy-load
          width="100%"
          height="100%"
          fit="contain"
          :src="require('@/assets/服务内容.png')"
        /> -->
        <div style="font-size: 16px">选择曼博士，你将享受一下服务</div>
      </div>
      <div class="service_content_pane">
        <div class="top_pane">
          <div class="pane_nav" style="background: #ffdad0">
            <div class="icon">
              <van-image
                lazy-load
                width="100%"
                height="100%"
                fit="contain"
                :src="require('@/assets/icon/评估计划.png')"
              />
            </div>
            <div class="subtitle">
              <h4>评估规划</h4>
              <i style="background: #ec7d32"></i>
            </div>
            <div class="text_content">
              <span>全面背景评估</span>
              <span>背景提升指导</span>
              <span>申请进度规划</span>
            </div>
          </div>

          <div class="pane_nav" style="background: #b7ddff">
            <div class="icon">
              <van-image
                lazy-load
                width="100%"
                height="100%"
                fit="contain"
                :src="require('@/assets/icon/研究计划.png')"
              />
            </div>
            <div class="subtitle">
              <h4>研究计划</h4>
              <i style="background: #67afff"></i>
            </div>
            <div class="text_content">
              <span>院校导师匹配</span>
              <span>研究计划课程指定</span>
              <span>研究计划写作辅导</span>
              <span>研究计划专业润色</span>
            </div>
          </div>
        </div>
        <div class="bottom_pane">
          <div class="pane_nav" style="background: #d1add6">
            <div class="icon">
              <van-image
                lazy-load
                width="100%"
                height="100%"
                fit="contain"
                :src="require('@/assets/icon/文书撰写.png')"
              />
            </div>
            <div class="subtitle">
              <h4>材料撰写</h4>
              <i style="background: #8f5496"></i>
            </div>
            <div class="text_content">
              <span>个人陈述撰写</span>
              <span>学术简历撰写</span>
              <span>推荐信撰写</span>
              <span>套磁信制作</span>
            </div>
          </div>

          <div class="pane_nav" style="background: #88e5e4">
            <div class="icon">
              <van-image
                lazy-load
                width="100%"
                height="100%"
                fit="contain"
                :src="require('@/assets/icon/网申服务.png')"
              />
            </div>
            <div class="subtitle">
              <h4>网申服务</h4>
              <i style="background: #1d807f"></i>
            </div>
            <div class="text_content">
              <span>网申表格填写</span>
              <span>模拟面试指导</span>
              <span>协助入学服务</span>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="share">
      <div class="left-title">
        <i></i>
        <h2>来自学长学姐的分享</h2>
      </div>
    </div>
    <share-swiper :items="students" />
    <div class="choice_mine">
      <div class="left-title" style="margin: 40px 0 15px">
        <i></i>
        <h2>选择曼博士，把梦想实现</h2>
      </div>
      <div class="choice_mine_content">
        <div class="choice_subtitle">
          <van-image
            lazy-load
            width="100%"
            height="100%"
            fit="contain"
            :src="require('@/assets/强大的博士导师阵容.png')"
          />
        </div>
        <div class="minhan_power">
          <div class="power_pane">
            <div class="top_pane">
              <div class="nav" style="margin-right: 0.2rem">
                <div class="nav_content">
                  <div class="nav_title">
                    <van-image
                      width="1em"
                      height="1em"
                      fit="contain"
                      :src="require('@/assets/icon/院校.png')"
                    />
                    <nav>院校</nav>
                  </div>
                  <div class="nav_text">
                    <span>海外TOP知名院校</span>
                  </div>
                </div>
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  :src="require('@/assets/chiose_background/院校.png')"
                />
              </div>
              <div class="nav">
                <div class="nav_content">
                  <div class="nav_title">
                    <van-image
                      width="1em"
                      height="1em"
                      fit="contain"
                      :src="require('@/assets/icon/专业.png')"
                    />
                    <nav>专业</nav>
                  </div>
                  <div class="nav_text">
                    <span>文、理、工、商、艺、医</span><span>六大专业方向</span>
                  </div>
                </div>
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  :src="require('@/assets/chiose_background/专业.png')"
                />
              </div>
            </div>
            <div class="bottom_pane">
              <div class="nav" style="margin-right: 0.2em">
                <div class="nav_content">
                  <div class="nav_title">
                    <van-image
                      width="1em"
                      height="1em"
                      fit="contain"
                      :src="require('@/assets/icon/领域.png')"
                    />
                    <nav>研究领域</nav>
                  </div>
                  <div class="nav_text">
                    <span>覆盖126个细分研究领域</span>
                  </div>
                </div>
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  :src="require('@/assets/chiose_background/研究领域.png')"
                />
              </div>
              <div class="nav">
                <div class="nav_content">
                  <div class="nav_title">
                    <van-image
                      width="1em"
                      height="1em"
                      fit="contain"
                      :src="require('@/assets/icon/带教.png')"
                    />
                    <nav>带教经验</nav>
                  </div>
                  <div class="nav_text">
                    <span>1000+名带教经验丰富</span><span>导师</span>
                  </div>
                </div>
                <van-image
                  width="100%"
                  height="100%"
                  fit="contain"
                  :src="require('@/assets/chiose_background/带教经验.png')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <share-swipertwo :items="doctors" />
    <div class="minhan_advantage">
      <div class="minhan_advantage_subtitle">
        <van-image
          lazy-load
          width="100%"
          height="100%"
          fit="contain"
          :src="require('@/assets/得天独厚的服务优势.png')"
        />
      </div>
      <div class="minhan_advantage_content">
        <van-image
          lazy-load
          width="100%"
          height="100%"
          fit="contain"
          :src="require('@/assets/text.png')"
        />
      </div>
    </div>
    <div class="minhan_wwyt">
      <!-- #需要改动，此处文本与五位一体联动 -->
      <div class="minhan_wwyt_subtitle">
        <van-image
          lazy-load
          width="100%"
          height="100%"
          fit="contain"
          :src="require('@/assets/五位一体 全方位陪伴.png')"
        />
      </div>
      <!-- <van-image lazy-load width="100%" height="100%" fit="contain" :src="require('@/assets/学员.png')"/> -->
      <wwyt />
    </div>

    <van-image
      lazy-load
      width="100%"
      height="100%"
      fit="contain"
      :src="require('@/assets/04孵千余全球名校博士.png')"
    />
    <!-- <div class="map">

    </div> -->
    <div class="admission">
      <div class="title"><h3>免费定制我的申博方案！</h3></div>
      <home-form />
    </div>
    <!-- foot -->
    <foot />
    <!-- 回到顶部 -->
    <div @click="toTop" v-show="toTopBtnFlag" id="toTop">
      <van-image
        width="70px"
        height="70px"
        fit="contain"
        :src="require('@/assets/回到顶部.png')"
      >
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <!-- 咨询小曼博士 -->
    <!-- <div class="consulting" @click="open">
      <van-image width="22px" height="22px" :src="require('@/assets/咨询 .png')" style="margin-right:3px"/>
      <p>咨询小曼博士</p>
    </div> -->
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import foot from "@/components/foot";
import documentPane from "@/components/documentPane";
import shareSwiper from "@/components/shareSwiper";
import shareSwipertwo from "@/components/shareSwiper2";
import Nav_head from "@/components/nav_h5";

import Toast from "vant/lib/toast";
import wwyt from "@/components/wwyt";
import systemContentPane from "@/components/systemConetntPane";
import homeForm from "@/components/homeForm";
export default {
  name: "Home",
  components: {
    foot,
    documentPane,
    shareSwiper,
    wwyt,
    systemContentPane,
    homeForm,
    shareSwipertwo,
    Nav_head
  },
  data() {
    return {
      students: [
        require("@/assets/students_share/student_share (1).png"),
        require("@/assets/students_share/student_share (2).png"),
        require("@/assets/students_share/student_share (3).png"),
        require("@/assets/students_share/student_share (4).png"),
        require("@/assets/students_share/student_share (5).png"),
        require("@/assets/students_share/student_share (6).png"),
        require("@/assets/students_share/student_share (7).png"),
        require("@/assets/students_share/student_share (8).png"),
        require("@/assets/students_share/student_share (9).png"),
      ],
      pageDefaultData: [
        {
          id: 1,
          path: require("@/assets/comment_documents/comment_document_1.png"),
          title: "学术简历（CV, Curriculum Vitae）",
          text:
            "主要包括个人信息、教育背景、学术论文或出版刊物、学术会议、研究经历、获奖经历、专业技能等，展现个人的教育和个人亮点。",
        },
        {
          id: 2,
          path: require("@/assets/comment_documents/comment_document_2.png"),
          title: "个人陈述（PS, Personal Statement）",
          text:
            "言简意赅，你的背景、研究方向、申请理由、研究计划等，需要大家在有限篇幅内说清楚自己要表达的意思。",
        },
        {
          id: 3,
          path: require("@/assets/comment_documents/comment_document_3.png"),
          title: "推荐信（RL, Referece Letter）",
          text:
            "2-3封推荐信，站在推荐人的角度评价学习能力，内容要包括对你的学习成绩、科研能力、合作精神和学术成就等方面的介绍，并且要求有盖章和专用信签纸等。",
        },
        {
          id: 4,
          path: require("@/assets/comment_documents/comment_document_4.png"),
          title: "研究计划（RP, Research Proposal）",
          text:
            "包含研究问题、与研究问题相关的方法论、最新的参考文献等，字数由学校自行规定。成功的proposal衡量标准是要说明研究内容、目标、现状、研究方法和研究价值，创新性非常重要。",
        },
        {
          id: 5,
          path: require("@/assets/comment_documents/comment_document_5.png"),
          title: "套磁信（CL, Cover Letter）",
          text:
            "和国外教授主动通过书信往来建立联系和提升彼此友好印象，进行学术探讨。",
        },
      ],
      showFlag: "a",
      doctors: [
        require("@/assets/doctors/doctor (1).png"),
        require("@/assets/doctors/doctor (2).png"),
        require("@/assets/doctors/doctor (3).png"),
        require("@/assets/doctors/doctor (4).png"),
        require("@/assets/doctors/doctor (5).png"),
        require("@/assets/doctors/doctor (6).png"),
        require("@/assets/doctors/doctor (7).png"),
        require("@/assets/doctors/doctor (8).png"),
        require("@/assets/doctors/doctor (9).png"),
        require("@/assets/doctors/doctor (10).png"),
        require("@/assets/doctors/doctor (11).png"),
        require("@/assets/doctors/doctor (12).png"),
        require("@/assets/doctors/doctor (13).png"),
        require("@/assets/doctors/doctor (14).png"),
        require("@/assets/doctors/doctor (15).png"),
      ],
      toTopBtnFlag: false,
    };
  },
  mounted() {
    let flag = true;
    window.addEventListener(
      "scroll",
      this.debounce((e) => {
        if (!this.toTopBtnFlag) {
          if (e.srcElement.scrollingElement.scrollTop > 500) {
            this.toTopBtnFlag = true;
          }
        }
        if (e.srcElement.scrollingElement.scrollTop <= 500) {
          this.toTopBtnFlag = false;
        }
      }, 100)
    );
  },
  methods: {
    debounce(func, wait, immediate) {
      var timeout, args, context, timestamp, result;
      var later = function() {
        // 据上一次触发时间间隔
        var last = new Date() - timestamp;
        // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
        if (last < wait && last > 0) {
          timeout = setTimeout(later, wait - last);
        } else {
          timeout = null;
          // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
          if (!immediate) {
            result = func.apply(context, args);
            if (!timeout) context = args = null;
          }
        }
      };

      return function() {
        context = this;
        args = arguments;
        timestamp = new Date();
        var callNow = immediate && !timeout;
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
          result = func.apply(context, args);
          context = args = null;
        }

        return result;
      };
    },
    toTop() {
      document.querySelector("#home").scrollIntoView({ behavior: "smooth" });
      this.toTopBtnFlag = false;
    },
    open() {
      openJesongChatByGroup(34320,48226);
      return false;
    },
  },
};
</script>

<style scoped>
.route {
  transition: all 0.3s;
}
.left-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.left-title span{
  font-size: 18px;
  transform-origin: 0% 50%;
  word-break: keep-all;
  display: inline-block;
}
.left-title i {
  display: inline-block;
  width: 3px;
  height: 14px;
  background: #8071F6;
  border-radius: 4px;
  margin-right: 2px;
}
@media (min-width: 320px) {
  .left-title i {
    width: 3px;
    height: 20px;
  }
  .left-title span{
    font-size: 18px;
    }
}
@media (min-width: 360px) {
  .left-title i {
    width: 3px;
    height: 18px;
  }
}
@media (min-width: 375px) {
  .left-title i {
    width: 3px;
    height: 20px;
  }
}
@media (min-width: 414px) {
  .left-title i {
    width: 3px;
    height: 18px;
  }
  .left-title span {
    font-size: 19px !important;
  }
}
@media (min-width: 768px) {
  .left-title i {
    display: inline-block;
    width: 8px;
    height: 34px;
    border-radius: 4px;
    margin-right: 6px;
  }
  .left-title span {
    font-size: 34px !important;
  }
}
@media (min-width: 1024px) {
  .left-title i {
    display: inline-block;
    width: 8px;
    height: 36px;
    border-radius: 4px;
    margin-right: 3px;
  }
  .left-title h2 {
    font-size: 34px !important;
  }
  #firstTitle {
    margin-top: 22vw;
  }
}
.map{
  width:750px;
  height:150vw;
  background:rgb(244,244,244 );
}
#toTop {
  position: fixed;
  z-index: 999;
  right: -6px;
  bottom: 48vw;
}
.innerTitle {
  position: relative;
  z-index: 1;
  color: white;
  left: 3vw;
  top: 14vw;
}
.consulting {
  position: fixed;
  width: 100%;
  height: 40px;
  background: #fff;
  color: #9c529c;
  font-size: 16px;
  z-index: 999;
  bottom: 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 40px 100px #666;
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .home {
    font-size: 24px;
  }
  .admission .title {
    font-size: 28px !important;
  }
}
@media (min-width: 1024px) {
  .home {
    font-size: 24px;
  }
  .admission .title {
    font-size: 28px !important;
  }
}
.banner {
  width: 100%;
  max-height: 629px;
}
.banner .banner-text{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 24vw;color:#FFFFFF;
  text-align: center;
}
.banner .banner-text span{
  font-size:23px;
  /* margin: 0 auto; */
}
.banner .banner-bottom .banner-bottom-text1{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 60vw;
  color:#FFFFFF;
  text-align: center;
  font-size:12px;
}

.banner .banner-bottom .banner-bottom-text2{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 68vw;color:#FFFFFF;
  text-align: center;
  font-size:12px;
}
.banner .banner-bottom span{
    padding:4px 6px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: 3vw;
    color:#8071F6;
}
/* .banner-bottom-text-container{
    padding:4px 6px;
    width: 60px;
   
    background: #FFFFFF;
    border-radius: 4px;
    margin-left: 3vw;
    color:#8071F6;
} */
.admission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.admission .title {
  color: #8071F6;
  letter-spacing: 3px;
  transform-origin: center;
  /* margin-top: 24px; */
}
.advantage {
  margin-top: 35px;
  padding: 0 3vw;
}
.left-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  float: left;
}
.left-title h2 {
  line-height: 35px;
  font-size: 22px;
  font-weight: 500;
}
.advantage-pane {
  width: 94vw;
  height: 94vw;
  position: relative;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.system {
  margin-top: 40px;
  padding: 0 3vw;
  width: 94vw;
  height: auto;
}
.system-content-pane {
  padding-top: 20px;
}
.system-content-pane .content-pane-top {
  width: 94vw;
  height: auto;
  margin-top: 30px;
}
.system-content-pane .content-pane-bottom {
  width: 94vw;
  height: auto;
  margin-top: 23px;
}
.documents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.documents .documents_title {
  width: 70vw;
  height: auto;
  margin: 40px 0 15px;
}
.documents_pane {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
}
.service {
  width: 100%;
  height: auto;
  margin-top: 0px;
}
.service_content {
  width: 94vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.service_content_title {
  width: 60vw;
  height: auto;
  /* margin: 25px 0; */
  margin: 40px 0 15px;

}
.service_content_pane {
  width: 94%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.service_content_pane .top_pane,
.service_content_pane .bottom_pane {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}
.service_content_pane .bottom_pane {
  margin-top: 3vw;
}
.service_content_pane .pane_nav {
  /* margin-right: 8px; */
  width: 39vw;
  height: 60vw;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* padding-left: 1.1em; */
  border-radius: 2vw;
}
.service_content_pane .pane_nav:nth-child(2) {
  margin-right: 0;
}
.service_content_pane .pane_nav:nth-child(4) {
  margin-right: 0;
}
.pane_nav .icon {
  width: 3.3em;
  height: 3.3em;
  margin: 0 auto;
  margin-top: 1.3em;
}
.pane_nav .subtitle {
  width: 100%;
  margin-top: 16px;
  color: #343434;
  text-align: center;
}
@media screen and (max-width: 420px) {
  .pane_nav .icon {
    width: 2.9em;
    height: 2.9em;
    margin: 0 auto;
    margin-top: 1.3em;
  }
  .service_content_pane .pane_nav {
    height: 60vw !important;
    /* padding-left: 10px; */
  }
}
@media screen and (max-width: 375px) {
  .service_content_pane .pane_nav {
    height: 64vw !important;
    /* padding-left: 10px; */
  }
}
@media screen and (max-width: 320px) {
  .pane_nav .icon {
    width: 2.4em !important;
    height: 2.4em !important;
    margin: 0 auto;
    margin-top: 1.2em;
  }
  .service_content_pane .pane_nav {
    height: 62vw !important;
    /* padding-left: 10px; */
  }
  .pane_nav span {
    font-size: 12px !important;
    text-align: center;
  }
  .pane_nav .subtitle {
    width: 100%;
    margin-top: 10px;
    color: #343434;
  }
}
@media (min-width: 768px) {
  .service_content_pane .pane_nav {
    width: 36vw !important;
    height: 53vw !important;
  }
  .pane_nav .subtitle {
    font-size: 26px !important;
  }
  .subtitle i {
    background: chartreuse;
    width: 2em !important;
    height: 6px !important;
    margin: 12px auto !important;
  }
  .pane_nav span {
    font-size: 22px !important;
    margin-top: 10px !important;
    text-align: center;
  }
}
@media (min-width: 1024px) {
  .service_content_pane .pane_nav {
    width: 34vw !important;
    height: 47vw !important;
  }
  .pane_nav .subtitle {
    font-size: 36px !important;
  }
  .subtitle i {
    background: chartreuse;
    width: 2em !important;
    height: 6px !important;
  }
  .pane_nav span {
    font-size: 30px !important;
    text-align: center;
  }
}
.pane_nav .text_content {
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.subtitle i {
  margin: 0.3em auto;
  display: block;
  background: chartreuse;
  width: 1.5em;
  height: 4px;
  border-radius: 2px;
}
.pane_nav .subtitle h3 {
  float: left;
}
.pane_nav span {
  color: #555;
  float: left;
  font-size: 14px;
  display: block;
  text-align: center;
  margin: 0.2em 0;
}
.share {
  width: 94vw;
  height: auto;
  margin: 0 auto;
  margin-top: 40px;
}
.choice_mine {
  width: 94vw;
}
.choice_mine_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.choice_subtitle {
  margin: 0 auto;
  width: 37vw;
}
.choice_mine_content .minhan_power {
  margin-top: 18px;
}
.minhan_power .power_pane {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
}
.minhan_power .power_pane .top_pane {
  margin-bottom: 0.2em;
}
.minhan_power .power_pane .top_pane,
.minhan_power .power_pane .bottom_pane {
  width: calc(100% - 7vw);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav {
  width: 100%;
  height: 100%;
  position: relative;
}
.nav_content .nav_title {
  left: 0;
  right: 0;
  top: 1em;
  position: absolute;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
@media (min-width: 768px) {
  .nav_content .nav_title {
    top: 1.4em;
  }
  .nav_content .nav_title {
    font-size: 26px;
  }
  .nav_content .nav_text span {
    margin-top: 10px;
    font-size: 22px !important;
  }
  .nav_content .nav_text {
    letter-spacing: 1px;
    top: 2.6em !important;
  }
}
@media (min-width: 1024px) {
  .nav_content .nav_title {
    font-size: 36px;
  }
  .nav_content .nav_text span:nth-child(1) {
    margin-top: 30px;
    font-size: 30px !important;
  }
  .nav_content .nav_text span:nth-child(2) {
    margin-top: 8px;
    font-size: 30px !important;
  }
}
.nav_content .nav_text {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 2.2em;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav_content .nav_text span {
  font-size: 12px;
}
.nav_content nav {
  display: inline-block;
  color: white;
  letter-spacing: 1px;
  margin-left: 4px;
  line-height: 0.8em;
}
.minhan_advantage {
  margin-top: 20px;
  margin-bottom: 20px;
  background: #363d45;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
}
.minhan_advantage_subtitle {
  width: 37vw;
}
.minhan_advantage_content {
  margin-top: 20px;
  width: 50vw;
}
.minhan_wwyt {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 94vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
}
.minhan_wwyt_subtitle {
  width: 37vw;
  margin-bottom: 20px;
}
 /* /deep/ .van-swipe__indicators{
 
  margin-top: 5px;
} */
</style>
