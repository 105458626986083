<template>
    <div class="admission-form">
      <form id="form" >
          <div class="input-border">
            <span>
              <span class="label">姓名:</span>
              <input v-model.lazy="formData.name" type="text" name="name" id="">
            </span>
          </div>
          <div class="input-border">
            <span>
                    
              <!-- <div background-image: url("@/assets/星号.png")></div> -->
              <!-- <image src="@/assets/星号.png" style=" width: 0.75rem;height: 0.69rem;"/> -->
              <!-- <font class="input-start"></font> -->

              <span class="label">手机号:</span>
              <input v-model.lazy="formData.contents" type="text" name="contents" id="">
            </span>
          </div>
         

          <div class="input-border">
            <span>
              <span class="label">意向国家:</span>
              <input v-model.lazy="formData.guojia" type="text" name="guojia" id="">
            </span>
          </div>
          <button type="button" class="form-submit-btn form-submit" @click.prevent="confirm($event)">一键GET录取率</button>
        </form>
      </div>
</template>
<script>
import Toast from 'vant/lib/toast';
import 'vant/lib/button/style';
export default {
    data(){
        return{
            formData:{
              name: '',
              contents: '',
              weixinhao: '',
              guojia: '',
            }
        }
    },
    methods:{
        confirm(event){
            // let wxreg = /^[a-zA-Z]{1}[-_a-zA-Z0-9]{5,19}$/;
            let phonereg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            let namereg = /^[\u4E00-\u9FA5A-Za-z\s]+(·[\u4E00-\u9FA5A-Za-z]+)*$/;
            if(this.formData.name == '' || namereg.test(this,this.formData.name)){
              return Toast('请输入您的姓名');
            }
            if(this.formData.contents == ''){
              return Toast('请输入您的手机号码');
            }
            if(!phonereg.test(this.formData.contents)){
              return Toast('请输入正确的手机号');
            }
            let data = new FormData()
            data.append("data[name]",this.formData.name);
            data.append("data[contents]",this.formData.contents);
            data.append("data[weixinhao]",this.formData.weixinhao);
            data.append("data[guojia]",this.formData.guojia);
            this.$axios.post('/index.php?c=index&a=ajax&modelid=10', data).then(res=>{
              console.log(res.data.err_code);
              if(res.data.err_code === 500){
                return Toast(res.data.msg);
              }else{
                Toast(res.data.msg);
                this.formData = {
                  name: '',
                  contents: '',
                  weixinhao: '',
                  guojia: '',
                }
              }
            })
        }
    }
}
</script>
<style  scoped >
  @media (min-width: 768px){
    .admission-form .input-border .label{
      font-size: 22px !important;
    }
    .admission-form .input-border input{
      font-size: 22px !important;
    }
  }
  @media (min-width: 1024px){
    .admission-form .input-border .label{
      font-size: 22px !important;
    }
    .admission-form .input-border input{
      font-size: 22px !important;
    }
  }
 .admission-form{
    display: flex;
    flex-direction: column;
    width: 94vw;
    height: 100%;
    margin-top: 24px;
  }
  .admission-form .input-border{
    width: 90vw;
    border: 1px solid #aaaaaa;
    padding: 11px 8px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  /* .admission-form .input-border .input-start{
     width: 0.75rem;
    height: 0.69rem;
    margin-right: 0.4rem;
    background-image: url("../../assets/星号.png");
  } */
  .admission-form .input-border span{
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  

  .admission-form .input-border .label{
    font-size: 14px;
    margin-right: 8px;
    color: #333;
    letter-spacing: 1px;
    word-break: keep-all;
  }
  @media screen and (max-width: 320px){
    .admission-form .input-border .label{
      font-size: 12px !important;
    }
    .admission-form .input-border input{
      font-size: 12px !important;
    }
  }
  @media screen and (max-width: 360px){
    .admission-form .input-border .label{
      font-size: 12px !important;
    }
    .admission-form .input-border input{
      font-size: 12px !important;
    }
  }
  .admission-form .input-border input{
    border: none;
    width: 70vw;
    font-size: 14px;
    outline:medium;
  }
  .form-submit-btn{
    border: none;
    outline:medium;
  }
  .form-submit{
    width: 100%;
    height: 45px;
    color: white;
    background: #8071F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    letter-spacing: 6px;
    cursor: pointer;
  }

</style>