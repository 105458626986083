import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import { Image as VanImage } from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
import { Loading } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Icon } from 'vant';
import { Toast } from 'vant';
import VueCookies from 'vue-cookies'
import Axios from './plugins/axios';

Vue.use(VueCookies);

Vue.use(Toast);

Vue.use(Icon);

Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(Loading);

Vue.use(Lazyload);

Vue.use(VanImage);

Vue.config.productionTip = false;

Vue.cookies = VueCookies;
window.cookies = VueCookies;
Object.defineProperties(Vue.prototype, {
  cookies: {
    get() {
      return VueCookies;
    }
  },
  $cookies: {
    get() {
      return VueCookies;
    }
  },
});
// this.$cookies.config('30d');
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
