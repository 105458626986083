import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '博士留学-英国博士申请-新加坡博士申请-澳洲博士申请-香港博士申请-曼博士'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   if(to.meta.title){
//     document.title = to.meta.title;
//   }
//   next();
// })

export default router
