<template>
  <div>
    <div id="systemContentPane">
      <nav>
        <dot
          class="dot"
          type="yg"
          :selected="checkedIndex == 'yg'"
          @check="checkCounty"
        />
      </nav>
      <nav>
        <dot
          class="dot"
          type="oz"
          :selected="checkedIndex == 'oz'"
          @check="checkCounty"
        />
      </nav>
      <nav>
        <dot
          class="dot"
          type="am"
          :selected="checkedIndex == 'am'"
          @check="checkCounty"
        />
      </nav>
      <nav>
        <dot
          class="dot"
          type="bm"
          :selected="checkedIndex == 'bm'"
          @check="checkCounty"
        />
      </nav>
      <nav>
        <dot
          class="dot"
          type="dyz"
          :selected="checkedIndex == 'dyz'"
          @check="checkCounty"
        />
      </nav>
      <nav>
        <dot
          class="dot"
          type="dny"
          :selected="checkedIndex == 'dny'"
          @check="checkCounty"
        />
      </nav>
      <van-image
        id="backgroundImage"
        width="97%"
        height="20vw"
        fit="contain"
        :src="require('@/assets/temp_route.png')"
      >
      </van-image>
    </div>
    <!-- #待优化 -->
    <van-image
      style="float: left"
      v-show="checkedIndex == 'yg'"
      width="100%"
      height="100%"
      fit="contain"
      :src="contents['yg']"
    />
    <van-image
      style="float: left"
      v-show="checkedIndex == 'oz'"
      width="100%"
      height="100%"
      fit="contain"
      :src="contents['oz']"
    />
    <van-image
      style="float: left"
      v-show="checkedIndex == 'am'"
      width="100%"
      height="100%"
      fit="contain"
      :src="contents['am']"
    />
    <van-image
      style="float: left"
      v-show="checkedIndex == 'bm'"
      width="100%"
      height="100%"
      fit="contain"
      :src="contents['bm']"
    />
    <van-image
      style="float: left"
      v-show="checkedIndex == 'dyz'"
      width="100%"
      height="100%"
      fit="contain"
      :src="contents['dyz']" 
    />
    <van-image
      style="float: left"
      v-show="checkedIndex == 'dny'"
      width="100%"
      height="100%"
      fit="contain"
      :src="contents['dny']"
    />
  </div>
</template>

<script>
import dot from "./comm/dot";
import VanImage from "vant/lib/image";
import VanLoading from "vant/lib/loading";
import "vant/lib/button/style";
export default {
  data() {
    return {
      checkedIndex: "yg",
      contents: {
        yg: require("@/assets/brief-content/英国.png"),
        oz: require("@/assets/brief-content/欧洲.png"),
        am: require("@/assets/brief-content/港澳.png"),
        bm: require("@/assets/brief-content/美国.png"),
        dyz: require("@/assets/brief-content/澳大利亚.png"),
        dny: require("@/assets/brief-content/东南亚.png"),
      },
    };
  },
  components: {
    VanImage,
    VanLoading,
    dot,
  },
  methods: {
    checkCounty(data) {
      this.checkedIndex = data.county;
    },
  },
};
</script>

<style scoped>
#systemContentPane {
  width: 100%;
  height: 30vw;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
#systemContentPane nav {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
#systemContentPane nav:nth-child(1) {
  justify-content: flex-end;
  align-items: flex-start;
}
#systemContentPane nav:nth-child(2) {
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 4vw;
}
#systemContentPane nav:nth-child(3) {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2.6vw;
}
#systemContentPane nav:nth-child(4) {
  justify-content: flex-start;
  align-items: center;
  margin-top: 3vw;
  margin-right: 1.5vw;
}
#systemContentPane nav:nth-child(5) {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2vw;
}
#systemContentPane nav:nth-child(6) {
  justify-content: center;
  align-items: flex-end;
  margin-top: 2vw;
}
#backgroundImage {
  position: absolute;
  bottom: 0;
  z-index: -999;
}
</style>
